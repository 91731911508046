<template>
    <div class="home">
        <b-img src="images/bild.jpg" fluid />
        <span v-html="$t('presentation.team')" />
        <b-card-group deck>
            <b-card style="max-width: 300px;" title="Albina Kladusak"
                img-src="images/team/Kladusak.jpg"
                img-top>
                <p class="card-text">
                    Partner<br/><br/>
                    <a href="mailto: albina.kladusak@ebnerstolz.de">albina.kladusak@ebnerstolz.de</a><br/>
                    Tel. +49 174 3320197
                </p>
            </b-card>
            <b-card style="max-width: 300px;" title="Dr. Verena Rauen"
                img-src="images/team/Rauen.jpg"
                img-top>
                <p class="card-text">
                    Managerin<br/><br/>
                    <a href="mailto: verena.rauen@ebnerstolz.de">verena.rauen@ebnerstolz.de</a><br/>
                    Tel. +49 173 2840836
                </p>
            </b-card>
            <b-card style="max-width: 300px;" title="Patrick Kaindl"
                img-src="images/team/Kaindl.jpg"
                img-top>
                <p class="card-text">
                    Consultant<br/><br/>
                    <a href="mailto: patrick.kaindl@ebnerstolz.de">patrick.kaindl@ebnerstolz.de</a><br/>
                    Tel. +49 895 4901-8135
                </p>
            </b-card>
        </b-card-group>
        <br/>
        <br/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'team',
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                auth: 'auth/auth'
            })
        }
    }
</script>