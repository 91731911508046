<template>
<div class="card" style="border-width: 0px">
    <div class="card-header px-0" style="background-color: #F8F8F8">
        {{ $t(`auction_list.${type}.title`) }}
    </div>
    <div class="card-body px-0" style="background-color: #F8F8F8">
        <b-tabs>
            <b-tab :title="$t(`auction_list.${type}.tab_list`)" active>
                <b-card class="mb-3 shadow" :title="$t(`auction_list.${type}.card_list`)">
                    <b-button v-b-toggle.help_createtender pill size="sm" variant="outline-secondary" class="info-button" v-if="!hasRole('Lieferant')"><em>i</em></b-button>    
                    <b-button variant="primary" :to="newItemLink" class="button-margin" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="folder-plus" />{{ $t(`auction_list.${type}.button_new`) }}</b-button>
                    <b-button variant="primary" @click.prevent="showImport" class="button-margin" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="file-earmark-arrow-down" />{{ $t(`auction_list.${type}.import`) }}</b-button>
                    <b-sidebar right id="help_createtender" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                        <div class="px-3 py-2">
                            <b-list-group>
                                <b-list-group-item class="user-select-all">{{ $t("help.createtender_0") }}</b-list-group-item>
                                <b-list-group-item>
                                    <p>{{ $t("help.createtender_1") }}</p>
                                    <p>{{ $t("help.createtender_1a") }}</p>
                                    <p>{{ $t("help.createtender_1b") }}</p>
                                </b-list-group-item>
                                <b-list-group-item>
                                    <p>{{ $t("help.createtender_2") }}</p>
                                    <p>{{ $t("help.createtender_2a") }}</p>
                                    <p>{{ $t("help.createtender_2b") }}</p>
                                </b-list-group-item>
                                <b-list-group-item>{{ $t("help.createtender_3") }}</b-list-group-item>
                                <b-list-group-item>{{ $t("help.createtender_4") }}</b-list-group-item>
                                <b-list-group-item>{{ $t("help.createtender_5") }}</b-list-group-item>
                                <b-list-group-item>{{ $t("help.createtender_6") }}</b-list-group-item>
                            </b-list-group>
                        </div>
                    </b-sidebar>
                    
                    <br />
                    <b-form-group horizontal :label="$t(`auction_list.${type}.search_title`)" class="mb-0">
                        <b-input-group>
                            <b-form-tags v-model="searchFilter" :placeholder="$t(`auction_list.${type}.search_placeholder`)" />
                            <b-input-group-append>
                                <b-button :disabled="!searchFilter" @click="searchFilter = ''">{{ $t(`auction_list.${type}.search_reset`) }}</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    <br />
                    <div class="table-scrollable">
                    <b-table striped hover :items="isAuction ? auctions: callForBids" :fields="auctionFields" :filter="searchFilter">
                        <template #cell(title)="data">
                            <router-link :to="isauction ? '/app/auktionen/view/' + data.item.id : '/app/ausschreibung/view/' + data.item.id" href="#">{{ data.item.title }}</router-link>
                        </template>
                        <template #cell(organizer)="data">
                            <template v-if="data.item.organizer">
                                {{data.item.organizer.company}}
                            </template>
                            <template v-if="!data.item.organizer">-</template>
                        </template>
                        <template #cell(sampling_status)="data">
                            <template v-if="data.item.sampling_status === 0">{{ $t(`auction_view.samplingstatus_0`) }}</template>
                            <template v-if="data.item.sampling_status === 1">{{ $t(`auction_view.samplingstatus_1`) }}</template>
                            <template v-if="data.item.sampling_status === 2">{{ $t(`auction_view.samplingstatus_2`) }}</template>
                            <template v-if="data.item.sampling_status === 3">{{ $t(`auction_view.samplingstatus_3`) }}</template>
                            <template v-if="data.item.sampling_status === 4">{{ $t(`auction_view.samplingstatus_4`) }}</template>
                        </template>
                        <template #cell(process_status)="data">
                            <template v-if="data.item.process_status === 0">{{ $t(`auction_view.processstatus_0`) }}</template>
                            <template v-if="data.item.process_status === 1">{{ $t(`auction_view.processstatus_1`) }}</template>
                            <template v-if="data.item.process_status === 2">{{ $t(`auction_view.processstatus_2`) }}</template>
                            <template v-if="data.item.process_status === 3">{{ $t(`auction_view.processstatus_3`) }}</template>
                            <template v-if="data.item.process_status === 4">{{ $t(`auction_view.processstatus_4`) }}</template>
                            <template v-if="data.item.process_status === 5">{{ $t(`auction_view.processstatus_5`) }}</template>
                            <template v-if="data.item.process_status === 6">{{ $t(`auction_view.processstatus_6`) }}</template>
                        </template>
                        <template #cell(actions)="data">
                            <b-link class="m-1" href="#" v-show="data.index > 0" @click="moveItemUp(data.item.id)"><v-icon name="angle-up" ></v-icon></b-link>
                            <b-link class="m-1" href="#" v-show="data.index < (isAuction ? auctions: callForBids).length-1" @click="moveItemDown(data.item.id)"><v-icon name="angle-down" ></v-icon></b-link>
                            <b-link class="m-1" href="#" :to="viewLink(data.item)"><b-icon icon="eye" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#" :to="editLink(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#" @click="requestConfirmation(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#" @click.prevent="clone(data.item)" v-if="(hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')) && data.item.status === 2"><v-icon name="copy"></v-icon></b-link>
                        </template>
                        <template #cell(status)="data">
                            {{getAuctionStatus(data.item.start_date, data.item.end_date)}}
                        </template>
                        <template #cell(tasks)="data">
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.basedata_checked ? 'Stammdaten zugewiesen (Ausschreibungszeitraum)' : 'Stammdaten noch nicht final (Ausschreibungszeitraum)'"><b-icon :icon="data.item.basedata_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.conditions_checked ? 'Grunddaten beschrieben' : 'Grunddaten noch offen'"><b-icon :icon="data.item.conditions_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.basedata_checked ? 'Stammdaten zugewiesen (Ausschreibungszeitraum)' : 'Stammdaten noch nicht final (Ausschreibungszeitraum)'"><b-icon :icon="data.item.basedata_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.items_checked ? 'Ausschreibungspositionen in Bearbeitung' : 'Ausschreibungspositionen in Bearbeitung'"><b-icon :icon="data.item.items_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.bidimport_checked ? 'Multi-Bidding-Einstellungen vorgenommen und mit Angebotsdokument abgeglichen' : 'Multi-Bidding-Einstellungen nicht final'"><b-icon :icon="data.item.bidimport_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.auctionparameter_checked ? 'Auktionsparameter eingestellt' : 'Auktionsparameter noch offen'"><b-icon :icon="data.item.auctionparameter_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.documents_checked ? 'Dokumenten-Zusammenstellung final' : 'Dokumenten-Zusammenstellung noch unvollständig'"><b-icon :icon="data.item.documents_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.userlist_checked ? 'Bieterliste final und Einladungslinks versendet' : 'Bietereinladungen offen'"><b-icon :icon="data.item.userlist_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.questionnaires_checked ? 'Fragebögen zugeordnet' : 'Fragebögen unvollständig'"><b-icon :icon="data.item.questionnaires_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.quotation_requirements_checked ? 'Anforderungen an das Angebot beschrieben' : 'Anforderungen an das Angebot offen'"><b-icon :icon="data.item.quotation_requirements_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.qualification_requirements_checked ? 'Qualifikations-Anforderungen beschrieben' : 'Qualifikations-Anforderungen offen'"><b-icon :icon="data.item.qualification_requirements_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.quantityprice_information_checked ? 'Mengen- und Preisinformationen beschrieben' : 'Mengen- und Preisinformationen offen'"><b-icon :icon="data.item.quantityprice_information_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.logistical_requirements_checked ? 'Logistische Anforderungen beschrieben' : 'Logistische Anforderungen offen'"><b-icon :icon="data.item.logistical_requirements_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.commercial_processing_checked ? 'Kaufmännische Abwicklung beschrieben' : 'Kaufmännische Abwicklung offen'"><b-icon :icon="data.item.commercial_processing_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.tenderterms_checked ? 'Vertragsbedingungen beschrieben' : 'Vertragsbedingungen offen'"><b-icon :icon="data.item.tenderterms_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <!-- ToDo: Zollanforderungen noch integrieren 
                            <b-tooltip target="customs_checked" :title="data.item.customs_checked ? 'Zollanforderungen beschrieben' : 'Zollanforderungen offen'"></b-tooltip>
                            -->
                        </template>
                    </b-table>
                    </div>
                    <br />
                    <b-button variant="primary" :to="newItemLink" class="button-margin" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="folder-plus" />{{ $t(`auction_list.${type}.button_new`) }}</b-button>
                    <b-modal ref="confirmationModal" hide-footer :title="$t(`auction_list.${type}.delete_title`)">
                        <div class="d-block text-center">
                            <p v-if="auctionContext">{{ $t(`auction_list.${type}.delete_message`, {title: auctionContext.title}) }}</p>
                        </div>
                        <b-button class="mt-3" variant="outline-danger" block @click="executeDelete"><b-icon icon="trash" />{{ $t(`auction_list.${type}.delete_button`) }}</b-button>
                    </b-modal>
                </b-card>
            </b-tab>

            <b-tab :title="$t(`auction_list.${type}.tab_archived`)">
                <b-card class="mb-3 shadow" :title="$t(`auction_list.${type}.card_archived`)">
                    <b-button v-b-toggle.help_archivedtender pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
                    <b-sidebar right id="help_archivedtender" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                        <div class="px-3 py-2">
                            <b-list-group>
                                <b-list-group-item class="user-select-all">{{ $t("help.archivedtender_1") }}</b-list-group-item>
                                <b-list-group-item class="user-select-all">{{ $t("help.archivedtender_2") }}</b-list-group-item>
                                <b-list-group-item class="user-select-all">{{ $t("help.archivedtender_3") }}</b-list-group-item>
                                <b-list-group-item class="user-select-all">{{ $t("help.archivedtender_4") }}</b-list-group-item>                                
                            </b-list-group>
                        </div>
                    </b-sidebar>
                    <b-form-group horizontal :label="$t(`auction_list.${type}.search_title`)" class="mb-0">
                    <b-input-group>
                        <b-form-tags v-model="searchFilter" :placeholder="$t(`auction_list.${type}.search_placeholder`)" />
                        <b-input-group-append>
                        <b-button :disabled="!searchFilter" @click="searchFilter = ''">{{ $t(`auction_list.${type}.search_reset`) }}</b-button>
                        </b-input-group-append>
                    </b-input-group>
                    </b-form-group>
                    <br />
                    <div class="table-scrollable">
                    <b-table striped hover :items="isAuction ? auctions_archived: callForBids_archived" :fields="auctionFields" :filter="searchFilter">
                        <template #cell(title)="data">
                            <router-link :to="'/app/auktionen/view/'+data.item.id" href="#">{{data.item.title}}</router-link>
                        </template>
                        <template #cell(organizer)="data">
                            <template v-if="data.item.organizer">
                                {{data.item.organizer.company}}
                            </template>
                            <template v-if="!data.item.organizer">-</template>
                        </template>
                        <template #cell(sampling_status)="data">
                            <template v-if="data.item.sampling_status === 0">{{ $t(`auction_view.samplingstatus_0`) }}</template>
                            <template v-if="data.item.sampling_status === 1">{{ $t(`auction_view.samplingstatus_1`) }}</template>
                            <template v-if="data.item.sampling_status === 2">{{ $t(`auction_view.samplingstatus_2`) }}</template>
                            <template v-if="data.item.sampling_status === 3">{{ $t(`auction_view.samplingstatus_3`) }}</template>
                            <template v-if="data.item.sampling_status === 4">{{ $t(`auction_view.samplingstatus_4`) }}</template>
                        </template>
                        <template #cell(process_status)="data">
                            <template v-if="data.item.process_status === 0">{{ $t(`auction_view.processstatus_0`) }}</template>
                            <template v-if="data.item.process_status === 1">{{ $t(`auction_view.processstatus_1`) }}</template>
                            <template v-if="data.item.process_status === 2">{{ $t(`auction_view.processstatus_2`) }}</template>
                            <template v-if="data.item.process_status === 3">{{ $t(`auction_view.processstatus_3`) }}</template>
                            <template v-if="data.item.process_status === 4">{{ $t(`auction_view.processstatus_4`) }}</template>
                            <template v-if="data.item.process_status === 5">{{ $t(`auction_view.processstatus_5`) }}</template>
                            <template v-if="data.item.process_status === 6">{{ $t(`auction_view.processstatus_6`) }}</template>
                        </template>
                        <template #cell(actions)="data">
                            <b-link class="m-1" href="#" :to="viewLink(data.item)"><b-icon icon="eye" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#" :to="editLink(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#" @click.prevent="clone(data.item)" v-if="(hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')) && data.item.status === 2"><v-icon name="copy"></v-icon></b-link>
                        </template>
                        <template #cell(status)="data">
                            {{getAuctionStatus(data.item.start_date, data.item.end_date)}}
                        </template>
                    </b-table>
                    </div>
                    <br />
                </b-card>
            </b-tab>

            <b-tab :title="$t(`auction_list.${type}.tab_template`)" v-if="!hasRole('Lieferant')">
                <b-card class="mb-3 shadow" :title="$t(`auction_list.${type}.card_template`)">
                    <b-button v-b-toggle.help_templatetender pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
                    <b-sidebar right id="help_templatetender" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                        <div class="px-3 py-2">
                            <b-list-group>
                                <b-list-group-item class="user-select-all">{{ $t("help.templatetender_1") }}</b-list-group-item>
                                <b-list-group-item class="user-select-all">{{ $t("help.templatetender_2") }}</b-list-group-item>
                                <b-list-group-item class="user-select-all">{{ $t("help.templatetender_3") }}</b-list-group-item>
                                <b-list-group-item class="user-select-all">{{ $t("help.templatetender_4") }}</b-list-group-item>                                
                            </b-list-group>
                        </div>
                    </b-sidebar>
                    <b-form-group horizontal :label="$t(`auction_list.${type}.search_title`)" class="mb-0">
                    <b-input-group>
                        <b-form-tags v-model="searchFilter" :placeholder="$t(`auction_list.${type}.search_placeholder`)" />
                        <b-input-group-append>
                        <b-button :disabled="!searchFilter" @click="searchFilter = ''">{{ $t(`auction_list.${type}.search_reset`) }}</b-button>
                        </b-input-group-append>
                    </b-input-group>
                    </b-form-group>
                    <br />
                    <div class="table-scrollable">
                    <b-table striped hover :items="isAuction ? auctions_template: callForBids_template" :fields="auctionFields" :filter="searchFilter">
                        <template #cell(title)="data">
                            <router-link :to="'/app/auktionen/view/'+data.item.id" href="#">{{data.item.title}}</router-link>
                        </template>
                        <template #cell(organizer)="data">
                            <template v-if="data.item.organizer">
                                {{data.item.organizer.company}}
                            </template>
                            <template v-if="!data.item.organizer">-</template>
                        </template>
                        <template #cell(sampling_status)="data">
                            <template v-if="data.item.sampling_status === 0">{{ $t(`auction_view.samplingstatus_0`) }}</template>
                            <template v-if="data.item.sampling_status === 1">{{ $t(`auction_view.samplingstatus_1`) }}</template>
                            <template v-if="data.item.sampling_status === 2">{{ $t(`auction_view.samplingstatus_2`) }}</template>
                            <template v-if="data.item.sampling_status === 3">{{ $t(`auction_view.samplingstatus_3`) }}</template>
                            <template v-if="data.item.sampling_status === 4">{{ $t(`auction_view.samplingstatus_4`) }}</template>
                        </template>
                        <template #cell(process_status)="data">
                            <template v-if="data.item.process_status === 0">{{ $t(`auction_view.processstatus_0`) }}</template>
                            <template v-if="data.item.process_status === 1">{{ $t(`auction_view.processstatus_1`) }}</template>
                            <template v-if="data.item.process_status === 2">{{ $t(`auction_view.processstatus_2`) }}</template>
                            <template v-if="data.item.process_status === 3">{{ $t(`auction_view.processstatus_3`) }}</template>
                            <template v-if="data.item.process_status === 4">{{ $t(`auction_view.processstatus_4`) }}</template>
                            <template v-if="data.item.process_status === 5">{{ $t(`auction_view.processstatus_5`) }}</template>
                            <template v-if="data.item.process_status === 6">{{ $t(`auction_view.processstatus_6`) }}</template>
                        </template>
                        <template #cell(actions)="data">
                            <b-link class="m-1" href="#" :to="viewLink(data.item)"><v-icon name="eye"></v-icon></b-link>
                            <b-link class="m-1" href="#" :to="editLink(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><v-icon name="edit"></v-icon></b-link>
                            <b-link class="m-1" href="#" @click.prevent="clone(data.item)" v-if="(hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')) && data.item.status === 2"><v-icon name="copy"></v-icon></b-link>
                        </template>
                        <template #cell(status)="data">
                            {{getAuctionStatus(data.item.start_date, data.item.end_date)}}
                        </template>
                    </b-table>
                    </div>
                    <br />
                </b-card>
            </b-tab>

            <b-tab :title="$t(`help.info`)" v-if="hasRole('Administrator')">
                <b-card-group deck>
                    <b-card class="mb-3 shadow">
                        <b-card-text>
                            <b-form-group label="Feldauswahl zur Beschreibung von Ausschreibungen oder Auktionen">
                                <b-form-checkbox-group stacked id="tenderfields" v-model="selectedTenderFields" name="tender-fields">
                                    <b>Organisation</b>
                                    <b-form-checkbox value="Einkaufsorganisation">Einkaufsorganisation</b-form-checkbox>
                                    <b-form-checkbox value="Einkäufergruppe">Einkäufergruppe</b-form-checkbox>
                                    <br />
                                    <b>Angebotsanforderungen</b>
                                    <b-form-checkbox value="Angebotsform">Angebotsform</b-form-checkbox>
                                    <b-form-checkbox value="Angebotsbindefrist">Angebotsbindefrist</b-form-checkbox>
                                    <b-form-checkbox value="Vergabeprozess (Gesamtvergabe vs. Vergabe in Losen)">Vergabeprozess (Gesamtvergabe vs. Vergabe in Losen)</b-form-checkbox>
                                    <b-form-checkbox value="Rückfragen">Rückfragen</b-form-checkbox>
                                    <b-form-checkbox value="Zuschlagskriterien">Zuschlagskriterien</b-form-checkbox>
                                    <b-form-checkbox value="Vergabeumfang">Vergabeumfang</b-form-checkbox>
                                    <br />
                                    <b>Qualifikationsanforderungen</b>
                                    <b-form-checkbox value="Zertifizierungen">Zertifizierungen</b-form-checkbox>
                                    <br />
                                    <b>Mengen- und Preisinformationen</b>
                                    <b-form-checkbox value="Währung">Währung</b-form-checkbox>
                                    <b-form-checkbox value="Mengenangaben">Mengenangaben</b-form-checkbox>
                                    <b-form-checkbox value="Bonusregelung, Rückvergütung">Bonusregelung, Rückvergütung</b-form-checkbox>
                                    <b-form-checkbox value="Preise, Konditionen, Nachlässe, Rabatte">Preise, Konditionen, Nachlässe, Rabatte</b-form-checkbox>
                                    <b-form-checkbox value="Preisanpassung, Preisgleitklausel">Preisanpassung, Preisgleitklausel</b-form-checkbox>
                                    <b-form-checkbox value="sonstige Preisregelungen">sonstige Preisregelungen</b-form-checkbox>
                                    <br />
                                    <b>Logistische Anforderungen</b>
                                    <b-form-checkbox value="Lieferorte">Lieferorte</b-form-checkbox>
                                    <b-form-checkbox value="Verpackung und Etikettierung">Verpackung und Etikettierung</b-form-checkbox>
                                    <b-form-checkbox value="Frachtkosten">Frachtkosten</b-form-checkbox>
                                    <b-form-checkbox value="Lagerhaltung, Bevorratung, Konsignation">Lagerhaltung, Bevorratung, Konsignation</b-form-checkbox>
                                    <br />
                                    <b>Kaufmännische Abwicklung</b>
                                    <b-form-checkbox value="Bestellprozess">Bestellprozess</b-form-checkbox>
                                    <b-form-checkbox value="Wareneingangsprozess">Wareneingangsprozess</b-form-checkbox>
                                    <b-form-checkbox value="Rechnungsstellung">Rechnungsstellung</b-form-checkbox>
                                    <b-form-checkbox value="sonstige kaufmännischen Rahmenbedingungen">sonstige kaufmännischen Rahmenbedingungen</b-form-checkbox>
                                    <br />
                                    <b>Vertragsbedingungen</b>
                                    <b-form-checkbox value="Vertragslaufzeit">Vertragslaufzeit</b-form-checkbox>
                                    <b-form-checkbox value="Vertragsverlängerung">Vertragsverlängerung</b-form-checkbox>
                                    <b-form-checkbox value="Versicherungen">Versicherungen</b-form-checkbox>
                                    <b-form-checkbox value="Sonstige Vertragsregelungen">Sonstige Vertragsregelungen</b-form-checkbox>
                                    <b-form-checkbox value="Garantiebedingungen">Garantiebedingungen</b-form-checkbox>
                                    <br />
                                </b-form-checkbox-group>
                            </b-form-group>
                            <br />
                            <div class="user-select-all">Feldauswahl: {{ selectedTenderFields }}</div>
                        </b-card-text>
                    </b-card>
                    
                    <b-card class="mb-3 shadow">
                        <b-card-text>
                            <b-form-group label="Feldauswahl zu Material- und Ausschreibungspositionen:">
                                <b-form-checkbox-group stacked id="itemfields" v-model="selectedItemFields" name="item-fields">
                                    <b>Stammdaten</b>
                                    <b-form-checkbox value="Materialnummer">Materialnummer</b-form-checkbox>
                                    <b-form-checkbox value="Materialtyp">Materialtyp</b-form-checkbox>
                                    <b-form-checkbox value="Materialklasse">Materialklasse</b-form-checkbox>
                                    <b-form-checkbox value="Warengruppe">Warengruppe</b-form-checkbox>
                                    <b-form-checkbox value="alte Materialnummer">alte Materialnummer</b-form-checkbox>
                                    <b-form-checkbox value="neue Materialnummer">neue Materialnummer</b-form-checkbox>
                                    <b-form-checkbox value="Lieferanten-Materialnummer">Lieferanten-Materialnummer</b-form-checkbox>
                                    <b-form-checkbox value="Marke">Marke</b-form-checkbox>
                                    <b-form-checkbox value="Steuerklassifikation">Steuerklassifikation</b-form-checkbox>
                                    <br />
                                    <b>Disposition</b>
                                    <b-form-checkbox value="Bestelllosgröße">Bestelllosgröße</b-form-checkbox>
                                    <b-form-checkbox value="Dispositionsart">Dispositionsart</b-form-checkbox>
                                    <b-form-checkbox value="Produktionsmenge (maximal)">Produktionsmenge (maximal)</b-form-checkbox>
                                    <b-form-checkbox value="Lieferort">Lieferort</b-form-checkbox>
                                    <b-form-checkbox value="Lagerort">Lagerort</b-form-checkbox>
                                    <b-form-checkbox value="Toleranz Überlieferung">Toleranz Überlieferung</b-form-checkbox>
                                    <b-form-checkbox value="Toleranz Unterlieferung">Toleranz Unterlieferung</b-form-checkbox>
                                    <b-form-checkbox value="Wiederbeschaffungszeit">Wiederbeschaffungszeit</b-form-checkbox>
                                    <b-form-checkbox value="gewünschtes Lieferdatum">gewünschtes Lieferdatum</b-form-checkbox>
                                    <b-form-checkbox value="Optionsmenge">Optionsmenge</b-form-checkbox>
                                    <br />
                                    <b>Verpackung und Etikettierung</b>
                                    <b-form-checkbox value="Verpackungseinheit">Verpackungseinheit</b-form-checkbox>
                                    <b-form-checkbox value="Ladungsträger">Ladungsträger</b-form-checkbox>
                                    <b-form-checkbox value="Verpackung">Verpackung</b-form-checkbox>
                                    <b-form-checkbox value="Etikettierungsvorschrift">Etikettierungsvorschrift</b-form-checkbox>
                                    <b-form-checkbox value="Gewicht je Verpackungseinheit">Gewicht je Verpackungseinheit</b-form-checkbox>
                                    <b-form-checkbox value="Gewicht je Palette">Gewicht je Palette</b-form-checkbox>
                                    <b-form-checkbox value="Maximale Palettenhöhe">Maximale Palettenhöhe</b-form-checkbox>
                                    <br />
                                    <b>Fracht</b>
                                    <b-form-checkbox value="Frachtvergütung">Frachtvergütung</b-form-checkbox>
                                    <br />
                                    <b>Spezifikationen</b>
                                    <b-form-checkbox value="Zeichnungsnummer">Zeichnungsnummer</b-form-checkbox>
                                    <b-form-checkbox value="Baugruppennummer">Baugruppennummer</b-form-checkbox>
                                    <b-form-checkbox value="Nettogewicht">Nettogewicht</b-form-checkbox>
                                    <b-form-checkbox value="Gewichtseinheit">Gewichtseinheit</b-form-checkbox>
                                    <b-form-checkbox value="Normbezeichnung">Normbezeichnung</b-form-checkbox>
                                    <b-form-checkbox value="EAN Nummer">EAN Nummer</b-form-checkbox>
                                    <b-form-checkbox value="Besonderheiten">Besonderheiten</b-form-checkbox>
                                    <b-form-checkbox value="Alternative Spezifikation (ja / nein)">Alternative Spezifikation (ja / nein)</b-form-checkbox>
                                    <b-form-checkbox value="Mindest-Rest-MHD bei Anlieferung">Mindest-Rest-MHD bei Anlieferung</b-form-checkbox>
                                    <b-form-checkbox value="Temperaturbedingung">Temperaturbedingung</b-form-checkbox>
                                    <br />
                                    <b>Kennzeichnung</b>
                                    <b-form-checkbox value="CAD-Kennzeichen">CAD-Kennzeichen</b-form-checkbox>
                                    <b-form-checkbox value="Gefahrgut">Gefahrgut</b-form-checkbox>
                                    <b-form-checkbox value="DIN-Norm">DIN-Norm</b-form-checkbox>
                                    <b-form-checkbox value="Prüfhinweis">Prüfhinweis</b-form-checkbox>
                                    <b-form-checkbox value="eClass Nummer">eClass Nummer</b-form-checkbox>
                                    <b-form-checkbox value="Gefahrstoffnummer">Gefahrstoffnummer</b-form-checkbox>
                                    <br />
                                    <b>Nachhaltigkeit</b>
                                    <b-form-checkbox value="CO2-Wert">CO2-Wert</b-form-checkbox>
                                    <b-form-checkbox value="Wasser-Verbrauch">Wasser-Verbrauch</b-form-checkbox>
                                    <b-form-checkbox value="Materialart Verpackungslizensierung">Materialart Verpackungslizensierung</b-form-checkbox>
                                    <b-form-checkbox value="Gewicht Verpackungslizensierung">Gewicht Verpackungslizensierung</b-form-checkbox>
                                    <br />
                                </b-form-checkbox-group>
                            </b-form-group>
                            <br />
                            <div class="user-select-all">Feldauswahl: {{ selectedItemFields }}</div>
                        </b-card-text>
                    </b-card>

                    <b-card class="mb-3 shadow">
                        <b-card-text>
                            <b-form-group label="Feldauswahl mit Lieferantenzuordnung:">
                                <b-form-checkbox-group stacked id="supplierfields" v-model="selectedSupplierFields" name="supplier-fields">
                                    <b>Nachhaltigkeit</b>
                                    <b-form-checkbox value="Audit Status">Audit Status</b-form-checkbox>
                                    <b-form-checkbox value="Beschwerdemanagement">Beschwerdemanagement</b-form-checkbox>
                                    <b-form-checkbox value="Risikomanagement">Risikomanagement</b-form-checkbox>
                                    <b-form-checkbox value="Risiko Land">Risiko Land</b-form-checkbox>
                                    <b-form-checkbox value="Risiko Rohmaterial">Risiko Rohmaterial</b-form-checkbox>
                                    <b-form-checkbox value="Risiko-Level">Risiko-Level</b-form-checkbox>
                                    <b-form-checkbox value="Umweltschutz">Umweltschutz</b-form-checkbox>
                                    <b-form-checkbox value="Menschenrechte">Menschenrechte</b-form-checkbox>
                                    <b-form-checkbox value="Code of Conduct">Code of Conduct</b-form-checkbox>
                                    <br />
                                    <b>Zertifikate, Audits und Protokolle</b>
                                    <b-form-checkbox value="Zertifikat Bezeichnung">Zertifikat Bezeichnung</b-form-checkbox>
                                    <b-form-checkbox value="Zertifikat Beginn">Zertifikat Beginn</b-form-checkbox>
                                    <b-form-checkbox value="Zertifikat Ende">Zertifikat Ende</b-form-checkbox>
                                    <b-form-checkbox value="Zertifikat Prüfungsstatus">Zertifikat Prüfungsstatus</b-form-checkbox>
                                    <b-form-checkbox value="Zertifikat Ablehnungsgrund">Zertifikat Ablehnungsgrund</b-form-checkbox>
                                    <b-form-checkbox value="Zertifikat Erinnerungsdatum">Zertifikat Erinnerungsdatum</b-form-checkbox>
                                    <b-form-checkbox value="Zertifikat Erinnerungsempfänger">Zertifikat Erinnerungsempfänger</b-form-checkbox>
                                    <b-form-checkbox value="Zertifikat Erinnerungstext">Zertifikat Erinnerungstext</b-form-checkbox>
                                    <b-form-checkbox value="Zertifikat geprüft am…">Zertifikat geprüft am…</b-form-checkbox>
                                    <b-form-checkbox value="Zertifikat geprüft durch…">Zertifikat geprüft durch…</b-form-checkbox>
                                    <b-form-checkbox value="Zertifikat Gültigkeit">Zertifikat Gültigkeit</b-form-checkbox>
                                    <b-form-checkbox value="Zertifikat Dateibezeichnung">Zertifikat Dateibezeichnung</b-form-checkbox>
                                    <b-form-checkbox value="Zertifikat ausstellende Stelle">Zertifikat ausstellende Stelle</b-form-checkbox>
                                    <b-form-checkbox value="Auditprotokoll Bezeichnung">Auditprotokoll Bezeichnung</b-form-checkbox>
                                    <b-form-checkbox value="Audit durchgeführt am…">Audit durchgeführt am…</b-form-checkbox>
                                    <b-form-checkbox value="Audit durchgeführt durch…">Audit durchgeführt durch…</b-form-checkbox>
                                    <br />
                                    <b>Sonstiges</b>
                                    <b-form-checkbox value="Verantwortlicher Einkäufer">Verantwortlicher Einkäufer</b-form-checkbox>
                                    <b-form-checkbox value="Teilnahme Dynamic Discounting">Teilnahme Dynamic Discounting</b-form-checkbox>
                                    <b-form-checkbox value="Anbindung Supplier Portal">Anbindung Supplier Portal</b-form-checkbox>
                                    <b-form-checkbox value="Materialnummer">Materialnummer</b-form-checkbox>
                                    <b-form-checkbox value="Materialbezeichnung">Materialbezeichnung</b-form-checkbox>
                                    <b-form-checkbox value="Geheimhaltungsvereinbarung">Geheimhaltungsvereinbarung</b-form-checkbox>
                                    <b-form-checkbox value="Lieferantenselbstauskunft">Lieferantenselbstauskunft</b-form-checkbox>
                                    <br />
                                </b-form-checkbox-group>
                            </b-form-group>
                            <br />
                            <div class="user-select-all">Feldauswahl: {{ selectedSupplierFields }}</div>
                        </b-card-text>
                    </b-card>
                </b-card-group>
            </b-tab>

            <b-tab title="Lieferkettengesetz" v-if="hasRole('Administrator')">
                <b-row>
                    <b-col cols="12" lg="3">
                        <b-list-group class="mb-2" v-b-scrollspy:listgroup-ex>
                            <b-list-group-item href="#list-item-1">A. Stammdaten</b-list-group-item>
                            <b-list-group-item href="#list-item-2">B. Berichterstattung und Grundsatzerklärung</b-list-group-item>
                            <b-list-group-item href="#list-item-3">C. Zertifikate und Initiativen</b-list-group-item>
                            <b-list-group-item href="#list-item-4">D. Risikomanagement</b-list-group-item>
                            <b-list-group-item href="#list-item-5">E. Steuerung der Vorlieferanten</b-list-group-item>
                            <b-list-group-item href="#list-item-6">F. Maßnahmen zur Risikoprävention</b-list-group-item>
                        </b-list-group>
                    </b-col>
            
                    <b-col cols="12" lg="9">
                        <div id="listgroup-ex" style="position:relative; overflow-y:auto; overflow-x:hidden; scrollbar-width:thin; height:700px">
                            <b-card id="list-item-1" title="A. Stammdaten" class="mb-3 shadow">
                                <b-row><b-col>
                                    <b-form-group label="Registernummer" label-for="Registernummer">
                                        <b-form-input id="Registernummer" placeholder="..."></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Namen der vertretungsberechtigten Organe (Geschäftsführer/Vorstand)" label-for="organe">
                                        <b-form-input id="name_1" placeholder=""></b-form-input>
                                        <b-form-input id="name_2" placeholder=""></b-form-input>
                                        <b-form-input id="name_3" placeholder=""></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Standorte" label-for="location">
                                        <b-form-input id="standort_1" placeholder=""></b-form-input>
                                        <b-form-input id="standort_2" placeholder=""></b-form-input>
                                        <b-form-input id="standort_3" placeholder=""></b-form-input>
                                        <b-form-input id="standort_4" placeholder=""></b-form-input>
                                        <b-form-input id="standort_5" placeholder=""></b-form-input>
                                    </b-form-group>

                                    <b-form-group label="Produktgruppe 1" label-for="productgroup1">
                                        <b-form-input id="product_11" placeholder=""></b-form-input>
                                        <b-form-input id="product_12" placeholder=""></b-form-input>
                                        <b-form-input id="product_13" placeholder=""></b-form-input>
                                        <b-form-input id="product_14" placeholder=""></b-form-input>
                                        <b-form-input id="product_15" placeholder=""></b-form-input>
                                    </b-form-group>

                                    <b-form-group label="Produktgruppe 2" label-for="productgroup2">
                                        <b-form-input id="product_21" placeholder=""></b-form-input>
                                        <b-form-input id="product_22" placeholder=""></b-form-input>
                                        <b-form-input id="product_23" placeholder=""></b-form-input>
                                        <b-form-input id="product_24" placeholder=""></b-form-input>
                                        <b-form-input id="product_25" placeholder=""></b-form-input>
                                    </b-form-group>

                                    <b-form-group label="Produktgruppe 3" label-for="productgroup3">
                                        <b-form-input id="product_31" placeholder=""></b-form-input>
                                        <b-form-input id="product_32" placeholder=""></b-form-input>
                                        <b-form-input id="product_33" placeholder=""></b-form-input>
                                        <b-form-input id="product_34" placeholder=""></b-form-input>
                                        <b-form-input id="product_35" placeholder=""></b-form-input>
                                    </b-form-group>

                                </b-col></b-row>
                            </b-card>

                            <b-card id="list-item-2" title="B. Berichterstattung und Grundsatzerklärung" class="mb-3 shadow">
                                <b-form-group label="Unterliegt Ihr Haus der Verpflichtung einer gesetzlichen Nachhaltigkeitsberichterstattung?" label-for="...">
                                    <b-form-select id="report" placeholder="">
                                        <option value="1">Ja</option>
                                        <option value="2">Nein</option>
                                    </b-form-select>
                                </b-form-group>
                                <b-form-group label="Oder veröffentlicht Ihr Haus einen freiwilligen Nachhaltigkeitsbericht?" label-for="...">
                                    <b-form-select id="report" placeholder="">
                                        <option value="1">Ja</option>
                                        <option value="2">Nein</option>
                                        <option value="3">Die Erstellung eines Nachhaltigkeitsberichtes ist geplant</option>
                                    </b-form-select>
                                </b-form-group>
                                
                                <b-form-group label="Verfügt Ihr Unternehmen über eine Grundsatzerklärung zu Menschen- und Umweltrechten?" label-for="...">
                                    <b-form-select id="report" placeholder="">
                                        <option value="1">ja</option>
                                        <option value="2">nein</option>
                                        <option value="3">Die Erstellung einer Grundsatzerklärung ist geplant</option>
                                    </b-form-select>
                                </b-form-group>
                                
                            </b-card>

                            <b-card id="list-item-3" title="C. Zertifikate und Initiativen" class="mb-3 shadow">
                                <b-form-group label="1. Verfügen Sie bereits über einschlägige Zertifizierungssysteme, wie z. B. ISO 14001, ISO 9001, EMAS, OHSAS 18001, ISO 27000?" label-for="...">
                                    <b-form-checkbox size="sm" class="ml-4">ISO 14001</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">ISO 9001</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">EMAS</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">OHSAS 18001</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">ISO 27000</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4 mt-2">weitere Zertifizierungssysteme</b-form-checkbox>
                                    <b-form-input size="sm" class="ml-4" id="" placeholder="Bitte geben Sie hier weitere Ihrer Zertifizierungssysteme an ..."></b-form-input>
                                </b-form-group>
                                <b-form-group label="2. Ist Ihr Haus im Bereich Risikomanagement zertifiziert (RMS, z.B. nach IDW PS 981) oder im Bereich Compliance Management (CMS, z.B. nach IDW 980) bzw. Interne Kontrollsysteme (IKS, z.B. nach IDW PS 982)?" label-for="...">
                                    <b-form-checkbox size="sm" class="ml-4">Risikomanagement-System nach IDW PS 981</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">Compliancemanagement-System nach IDW 980</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">Internes Kontrollsystem nach IDW PS 982</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4 mt-2">Einsatz sonstiger Systeme</b-form-checkbox>
                                    <b-form-input size="sm" class="ml-4" id="" placeholder="Bitte geben Sie hier Informationen zu sonstigen Ihrer Risikomanagement-Systeme an ..."></b-form-input>
                                </b-form-group>
                                <b-form-group label="3. Bitte laden Sie hier Ihre aktuellen Zertifikate hoch" label-for="...">
                                    <b-form-file size="sm" placeholder="Dokument hochladen..." id="..."></b-form-file>
                                </b-form-group>
                                <b-form-group label="4. Sind Sie Mitglied einer einschlägigen Initiative, wie z. B. EcoVadis, CDP (Carbon Disclosure Project), UNGC (UN Global Compact), WBCSD (World Business Council for Sustainable Development), CSR Europe, Globale Wirtschaftsinitiative für Menschenrechte (GBI)?" label-for="...">
                                    <b-form-checkbox size="sm" class="ml-4">EcoVadis</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">CDP (Carbon Disclosure Project)</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">UNGC (UN Global Compact)</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">WBCSD (World Business Council for Sustainable Development)</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">CSR Europe</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">Globale Wirtschaftsinitiative für Menschenrechte (GBI)</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4 mt-2">Mitglied weiterer Initiativen</b-form-checkbox>
                                    <b-form-input size="sm" class="ml-4" id="" placeholder="Bitte geben Sie hier weiterführende Informationen zu Ihren Mitgliedschaften bei sonstigen Initiativen an ..."></b-form-input>
                                </b-form-group>
                            </b-card>

                            <b-card id="list-item-4" title="D. Risikomanagement" class="mb-3 shadow">
                                <b-form-group label="1. Führen Sie regelmäßig eine Risikoanalyse im Hinblick auf Einhaltung von Menschenrechten und Umweltvorgaben in Ihrem Unternehmen und bei Ihren Lieferanten durch?" label-for="...">
                                    <b-form-select id="report" placeholder="">
                                        <option value="1">Ja, im eigenen Unternehmen</option>
                                        <option value="2">Ja, im eigenen Unternehmen und bei Vorlieferanten</option>
                                        <option value="3">Nein</option>
                                    </b-form-select>
                                </b-form-group>

                                <b-form-group label="2. Auditieren Sie Ihre Lieferanten hierzu, z.B. im Rahmen des QM-Prozesses oder der Lieferantenbewertung?" label-for="...">
                                    <b-form-select id="report" placeholder="">
                                        <option value="1">Ja</option>
                                        <option value="2">Nein</option>
                                        <option value="3">Nein, aber eine Auditierung des Risikomanagemnt-Systems unserer Vorlieferanten ist geplant</option>
                                    </b-form-select>
                                </b-form-group>
                                
                                <b-form-group label="3. Haben Sie einen Risikomanagementprozess in Ihrem Unternehmen/Ihrer Unternehmensgruppe etabliert und werden in diesem Zusammenhang Risiken regelmäßig identifiziert, bewertet und gesteuert?" label-for="...">
                                    <b-form-select id="report" placeholder="">
                                        <option value="1">Ja</option>
                                        <option value="2">Nein, aber wir beabsichtigen einen Risikomanagementprozess einzuführen</option>
                                        <option value="3">Nein</option>
                                    </b-form-select>
                                </b-form-group>

                                <b-form-group label="4. Falls ja, werden umweltbezogen und menschenrechtliche Risiken in das Risikomanagementsystem (RMS) auch einbezogen?" label-for="...">
                                    <b-form-select id="report" placeholder="">
                                        <option value="1">Ja</option>
                                        <option value="2">Nein</option>
                                    </b-form-select>
                                </b-form-group>
                            </b-card>

                            <b-card id="list-item-5" title="E. Steuerung der Vorlieferanten" class="mb-3 shadow">
                                
                            </b-card>

                            <b-card id="list-item-6" title="F. Maßnahmen zur Risikoprävention" class="mb-3 shadow">
                                
                            </b-card>
                        </div>
                    </b-col>
                </b-row>
               
            </b-tab>
        </b-tabs>
    </div>
    <AuctionUploadModal ref="auctionUploadModal" :savedCallback="fetchList" :isAuction="isAuction"/>
</div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import AuctionUploadModal from './modules/AuctionUploadModal';
import CallForBidsService from '../../../../services/CallForBidsService';
import { dateHelper } from '../../../mixins/dateHelper';

export default {
    name: 'callforbids-list',
    components: {
        AuctionUploadModal,
    },
    mixins: [dateHelper],
    data() {
        return {
            archived:'',
            template:'',
            searchFilter: '',
            auctionContext: null,
            isAuction: false,
            type: "auction",
            selectedTenderFields: [],
            selectedItemFields: [],
            selectedSupplierFields: []
        }
    },
    created() {
        this.isAuction = this.$route.path.indexOf('/app/auktionen') > -1;
        this.type = this.isAuction ? "auction" : "tender";
        this.fetchList();
    },
    watch: {
        '$route' (to, from) {
            this.isAuction = this.$route.path.indexOf('/app/auktionen') > -1;
            this.type = this.isAuction ? "auction" : "tender";
            this.fetchList();
        }
    },
    methods: {
        ...mapActions({
            listAuctions: 'callForBids/fetchAuctions',
            listCallForBids: 'callForBids/fetchCallForBids',
            listArchivedAuctions: 'callForBids/fetchArchivedAuctions',
            listArchivedCallForBids: 'callForBids/fetchArchivedCallForBids',
            listTemplateAuctions: 'callForBids/fetchTemplateAuctions',
            listTemplateCallForBids: 'callForBids/fetchTemplateCallForBids',
            deleteAuction: 'callForBids/delete'
        }),
        requestConfirmation(auction) {
            this.auctionContext = auction;
            this.$refs.confirmationModal.show();
        },
        moveItemUp(auctionId) {
            this.$store.commit('loading/setLoading', true);
            CallForBidsService.moveAuctionUp(auctionId)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    this.fetchList();
                });
        },
        moveItemDown(auctionId) {
            this.$store.commit('loading/setLoading', true);
            CallForBidsService.moveAuctionDown(auctionId)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    this.fetchList();
                });
        },
        executeDelete() {
            this.$refs.confirmationModal.hide();
            this.deleteAuction(this.auctionContext.id)
                    .then(response => {
                        if (response.status == 200) {
                            this.$notify({
                                group: 'global',
                                title: this.$t(`auction_list.${this.type}.delete_notify_title`),
                                type: 'success',
                                text: this.$t(`auction_list.${this.type}.delete_notify_message`)
                            })
                        } else {
                            this.$notify({
                                group: 'global',
                                title: 'Fehler beim Löschen der Vergabe',
                                type: 'error',
                                text: response.data.message
                            });
                        }
                        this.auctionContext = null;
                    });
        },
        fetchList() {
            this.isAuction = this.$route.path.indexOf('/app/auktionen') > -1;
            if(this.isAuction) {
                this.listAuctions();
                this.listArchivedAuctions();
                this.listTemplateAuctions();
            }else {
                this.listCallForBids();
                this.listArchivedCallForBids();
                this.listTemplateCallForBids();
            }
        },
        editLink(item) {
            return this.isAuction ? '/app/auktionen/edit/' + item.id : '/app/ausschreibung/edit/' + item.id;
        },
        viewLink(item) {
            return this.isAuction ? '/app/auktionen/view/' + item.id : '/app/ausschreibung/view/' + item.id;
        },
        showImport() {
            this.$refs.auctionUploadModal.showModal();
        },
        clone(item) {
            this.$store.commit('loading/setLoading', true);
            CallForBidsService.clone(item.id)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    if(response.data.success) {
                        this.$notify({
                                group: 'global',
                                title: this.$t(`auction_list.${this.type}.clone_success_title`),
                                type: 'success',
                                text: this.$t(`auction_list.${this.type}.clone_success_message`)
                            });
                    }else {
                        this.$notify({
                                group: 'global',
                                title: this.$t(`auction_list.${this.type}.clone_error_title`),
                                type: 'error',
                                text: response.data.message
                            });
                    }
                });
        },
        getAuctionStatus(startDate, endDate) {

            if(!this.$moment(startDate).isBefore(this.$moment())) {
                return this.$t(`auction_list.${this.type}.status_beforestart`)
            }

            if(this.$moment(endDate).isBefore(this.$moment())) {
                return this.$t(`auction_list.${this.type}.status_finished`)
            }

            return this.$t(`auction_list.${this.type}.status_running`);
        }
    },
    computed: {
        ...mapGetters({
            auctions: 'callForBids/auctions',
            callForBids: 'callForBids/callForBids',
            auctions_archived: 'callForBids/auctions_archived',
            callForBids_archived: 'callForBids/callForBids_archived',
            auctions_template: 'callForBids/auctions_template',
            callForBids_template: 'callForBids/callForBids_template',
            hasRole: 'auth/hasRole'
        }),
        newItemLink() {
            return this.isAuction ? '/app/auktionen/new' : '/app/ausschreibung/new';
        },
        auctionFields() {
            let fields = [
                {
                    key: 'id',
                    label: this.$t(`auction_list.${this.type}.column_id`)
                },
                {
                    key: 'title',
                    label: this.$t(`auction_list.${this.type}.column_title`),
                    sortable: true
                },
                {
                    key: 'organizer',
                    label: this.$t(`auction_list.${this.type}.column_organizer`),
                    sortable: true
                },
                {
                    key: 'start_date',
                    label: this.$t(`auction_list.${this.type}.column_start`),
                    sortable: true,
                    formatter: this.dateTimeFormatter
                },
                {
                    key: 'end_date',
                    label: this.$t(`auction_list.${this.type}.column_end`),
                    sortable: true,
                    formatter: this.dateTimeFormatter
                },
                {
                    key: 'status',
                    label: this.$t(`auction_list.${this.type}.column_status`)
                }
            ];

            if(this.hasRole('Administrator') || this.hasRole('Berater') || this.hasRole('Erweiterter Einkäufer')) {
                fields.push({
                    key: 'sampling_status',
                    label: this.$t(`auction_list.${this.type}.column_sampling_status`),
                    sortable: true
                });
                fields.push({
                    key: 'process_status',
                    label: this.$t(`auction_list.${this.type}.column_process_status`),
                    sortable: true
                });
                fields.push({
                    key: 'tasks',
                    label: 'Trackpoints  '
                });
                
            }

            fields.push({
                    key: 'actions',
                    label: this.$t(`auction_list.${this.type}.column_actions`)
                });

            return fields;
        },
    }
};
</script>
