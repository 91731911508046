<template>
<div class="card" style="border-width: 0px">
    <div class="card-header px-0" style="background-color: #f4f5fa">
        {{ $t("user.header_profile") }} <v-icon style="height: 12px" vertical-align="baseline" class="mb-1" name="chevron-right" /> {{ user.name }}
    </div>
    <div class="card-body px-0" style="background-color: #f4f5fa">
        <b-tabs>
            <b-tab :title="$t(`user.tab_editprofile`)" active>
                <b-card :title="$t(`user.card_editprofile`)" class="mb-3 shadow">
                    <b-form @submit.prevent="onSubmitUserInfo" v-show="doneLoading">
                        <b-row>
                            <b-col>
                                <b-form-group :label="$t(`user.name`)" label-for="user-name">
                                    <b-form-input id="user-name" type="text" v-model="user.name" :state="!$v.user.name.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.password`)" label-for="user-password">
                                        <b-form-input id="user-password" type="password" v-model="user.password" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.passwordrepeat`)" label-for="user-password-repeat">
                                        <b-form-input id="user-password-repeat" type="password" v-model="user.password_repeat" :state="!$v.user.password_repeat.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.contact`)" label-for="user-contact">
                                    <b-form-input id="user-contact" type="text" v-model="user.contact" :state="!$v.user.contact.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.phone`)" label-for="user-phone">
                                    <b-form-input id="user-phone" type="text" v-model="user.phone" :state="!$v.user.phone.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.email`)" label-for="user-email">
                                    <b-form-input id="user-email" type="email" v-model="user.email" :state="!$v.user.email.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.businessunit`)" label-for="user-business-unit">
                                    <b-form-input id="user-business-unit" type="text" v-model="user.business_unit" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-checkbox v-if="!hideAgreements" v-model="user.accepted_terms_of_service" class="terms-text">
                                    {{ $t("user.accept_termsofservice_1") }}<a href="#" @click.prevent="showTermsOfService">{{ $t("user.accept_termsofservice_2") }}</a>.
                                </b-form-checkbox>
                                <b-form-checkbox v-if="!hideAgreements" v-model="user.accepted_dsgvo" class="terms-text">
                                    {{ $t("user.accept_dsgvo") }}
                                </b-form-checkbox>

                                <b-form-group label="Land" label-for="...">
                                    <b-form-select id="courtry" placeholder="">
                                        <option value=1>Ägypten</option>
                                        <option value=2>Albanien</option>
                                        <option value=3>Andorra</option>
                                        <option value=4>Australien</option>
                                        <option value=5>Bangladesch</option>
                                        <option value=6>Belgien</option>
                                        <option value=7>Bosnien und Herzegowina</option>
                                        <option value=8>Bulgarien</option>
                                        <option value=9>China</option>
                                        <option value=10>Dänemark</option>
                                        <option value=11>Deutschland</option>
                                        <option value=12>Estland</option>
                                        <option value=13>Finnland</option>
                                        <option value=14>Frankreich</option>
                                        <option value=15>Griechenland</option>
                                        <option value=16>Großbritannien</option>
                                        <option value=17>Hongkong</option>
                                        <option value=18>Indien</option>
                                        <option value=19>Indonesien</option>
                                        <option value=20>Irland</option>
                                        <option value=21>Island</option>
                                        <option value=22>Israel</option>
                                        <option value=23>Italien</option>
                                        <option value=24>Japan</option>
                                        <option value=25>Kanada</option>
                                        <option value=26>Kenia</option>
                                        <option value=27>Kolombien</option>
                                        <option value=28>Kosovo</option>
                                        <option value=29>Kroatien</option>
                                        <option value=30>Kuba</option>
                                        <option value=31>Lettland</option>
                                        <option value=32>Liechtenstein</option>
                                        <option value=33>Litauen</option>
                                        <option value=34>Luxemburg</option>
                                        <option value=35>Madagaskar</option>
                                        <option value=36>Malaysia</option>
                                        <option value=37>Malta</option>
                                        <option value=38>Marokko</option>
                                        <option value=39>Mexiko</option>
                                        <option value=40>Moldau</option>
                                        <option value=41>Monaco</option>
                                        <option value=42>Montenegro</option>
                                        <option value=43>Myanmar</option>
                                        <option value=44>Nepal</option>
                                        <option value=45>Niederlande</option>
                                        <option value=46>Nordmazedonien</option>
                                        <option value=47>Norwegen</option>
                                        <option value=48>Österreich</option>
                                        <option value=49>Pakistan</option>
                                        <option value=50>Panama</option>
                                        <option value=51>Peru</option>
                                        <option value=52>Philippinen</option>
                                        <option value=53>Polen</option>
                                        <option value=54>Portugal</option>
                                        <option value=55>Rumänien</option>
                                        <option value=56>Russland</option>
                                        <option value=57>Schweden</option>
                                        <option value=58>Schweiz</option>
                                        <option value=59>Serbien</option>
                                        <option value=60>Singapur</option>
                                        <option value=61>Slowakei</option>
                                        <option value=62>Slowenien</option>
                                        <option value=63>Spanien</option>
                                        <option value=64>Südafrika</option>
                                        <option value=65>Südkorea</option>
                                        <option value=66>Taiwan</option>
                                        <option value=67>Thailand</option>
                                        <option value=68>Tschechien</option>
                                        <option value=69>Tunesien</option>
                                        <option value=70>Türkei</option>
                                        <option value=71>Ukraine</option>
                                        <option value=72>Ungarn</option>
                                        <option value=73>USA</option>
                                        <option value=74>Vereinigte Arabische Emirate</option>
                                        <option value=75>Vietnam</option>
                                        <option value=76>Zypern</option>
                                                                           
                                    </b-form-select>
                                </b-form-group>
                                <b-form-group label="Branche" label-for="...">
                                    <b-form-select id="section" placeholder="">
                                        <option value="1">Landwirtschaft, Jagd und damit verbundene Tätigkeiten</option>
                                        <option value="2">Anbau einjähriger Pflanzen</option>
                                        <option value="3">Anbau mehrjähriger Pflanzen</option>
                                        <option value="4">Betrieb von Baumschulen sowie Anbau von Pflanzen zu Vermehrungszwecken</option>
                                        <option value="5">Tierhaltung</option>
                                        <option value="6">Gemischte Landwirtschaft</option>
                                        <option value="7">Erbringung von landwirtschaftlichen Dienstleistungen</option>
                                        <option value="8">Jagd, Fallenstellerei und damit verbundene Tätigkeiten</option>
                                        <option value="9">Anbau von Getreide (ohne Reis), Hülsenfrüchten und Ölsaaten</option>
                                        <option value="10">Anbau von Reis</option>
                                        <option value="11">Anbau von Gemüse und Melonen sowie Wurzeln und Knollen</option>
                                        <option value="12">Anbau von Zuckerrohr</option>
                                        <option value="13">Anbau von Tabak</option>
                                        <option value="14">Anbau von Faserpflanzen</option>
                                        <option value="15">Anbau von sonstigen einjährigen Pflanzen</option>
                                        <option value="16">Anbau von Wein- und Tafeltrauben</option>
                                        <option value="17">Anbau von tropischen und subtropischen Früchten</option>
                                        <option value="18">Anbau von Zitrusfrüchten</option>
                                        <option value="19">Anbau von Kern- und Steinobst</option>
                                        <option value="20">Anbau von sonstigem Obst und Nüssen</option>
                                        <option value="21">Anbau von ölhaltigen Früchten</option>
                                        <option value="22">Anbau von Pflanzen zur Herstellung von Getränken</option>
                                        <option value="23">Anbau von Gewürzpflanzen, Pflanzen für aromatische, narkotische und pharmazeutische Zwecke</option>
                                        <option value="24">Anbau sonstiger mehrjähriger Pflanzen</option>
                                        <option value="25">Haltung von Milchkühen</option>
                                        <option value="26">Haltung von anderen Rindern</option>
                                        <option value="27">Haltung von Pferden und Eseln</option>
                                        <option value="28">Haltung von Kamelen</option>
                                        <option value="29">Haltung von Schafen und Ziegen</option>
                                        <option value="30">Haltung von Schweinen</option>
                                        <option value="31">Haltung von Geflügel</option>
                                        <option value="32">Sonstige Tierhaltung</option>
                                        <option value="33">Erbringung von landwirtschaftlichen Dienstleistungen für den Pflanzenbau</option>
                                        <option value="34">Erbringung von landwirtschaftlichen Dienstleistungen für die Tierhaltung</option>
                                        <option value="35">Nach der Ernte anfallende Tätigkeiten in der pflanzlichen Erzeugung</option>
                                        <option value="36">Saatgutaufbereitung</option>
                                        <option value="37">Forstwirtschaft und Holzeinschlag</option>
                                        <option value="38">Fischerei und Aquakultur</option>
                                        <option value="39">Fischerei</option>
                                        <option value="40">Aquakultur</option>
                                        <option value="41">Meeresfischerei</option>
                                        <option value="42">Süßwasserfischerei</option>
                                        <option value="43">Meeresaquakultur</option>
                                        <option value="44">Süßwasseraquakultur</option>
                                        <option value="45">Kohlenbergbau</option>
                                        <option value="46">Steinkohlenbergbau</option>
                                        <option value="47">Braunkohlenbergbau</option>
                                        <option value="48">Gewinnung von Erdöl und Erdgas</option>
                                        <option value="49">Gewinnung von Erdöl</option>
                                        <option value="50">Gewinnung von Erdgas</option>
                                        <option value="51">Erzbergbau</option>
                                        <option value="52">Eisenerzbergbau</option>
                                        <option value="53">NE-Metallerzbergbau</option>
                                        <option value="54">Bergbau auf Uran- und Thoriumerze</option>
                                        <option value="55">Sonstiger NE-Metallerzbergbau</option>
                                        <option value="56">Gewinnung von Steinen und Erden, sonstiger Bergbau</option>
                                        <option value="57">Gewinnung von Natursteinen, Kies, Sand, Ton und Kaolin</option>
                                        <option value="58">Sonstiger Bergbau; Gewinnung von Steinen und Erden a.n.g.</option>
                                        <option value="59">Gewinnung von Naturwerksteinen und Natursteinen, Kalk- und Gipsstein, Kreide und Schiefer</option>
                                        <option value="60">Gewinnung von Kies, Sand, Ton und Kaolin</option>
                                        <option value="61">Bergbau auf chemische und Düngemittelminerale</option>
                                        <option value="62">Torfgewinnung</option>
                                        <option value="63">Gewinnung von Salz</option>
                                        <option value="64">Gewinnung von Steinen und Erden a.n.g.</option>
                                        <option value="65">Erbringung von Dienstleistungen für den Bergbau und für die Gewinnung von Steinen und Erden</option>
                                        <option value="66">Erbringung von Dienstleistungen für die Gewinnung von Erdöl und Erdgas</option>
                                        <option value="67">Erbringung von Dienstleistungen für den sonstigen Bergbau und die Gewinnung von Steinen und Erden</option>
                                        <option value="68">Herstellung von Nahrungs- und Futtermitteln</option>
                                        <option value="69">Schlachten und Fleischverarbeitung</option>
                                        <option value="70">Fischverarbeitung</option>
                                        <option value="71">Obst- und Gemüseverarbeitung</option>
                                        <option value="72">Herstellung von pflanzlichen und tierischen Ölen und Fetten</option>
                                        <option value="73">Milchverarbeitung</option>
                                        <option value="74">Mahl- und Schälmühlen, Herstellung von Stärke und Stärkeerzeugnissen</option>
                                        <option value="75">Herstellung von Back- und Teigwaren</option>
                                        <option value="76">Herstellung von sonstigen Nahrungsmitteln</option>
                                        <option value="77">Herstellung von Futtermitteln</option>
                                        <option value="78">Schlachten (ohne Schlachten von Geflügel)</option>
                                        <option value="79">Schlachten von Geflügel</option>
                                        <option value="80">Fleischverarbeitung</option>
                                        <option value="81">Kartoffelverarbeitung</option>
                                        <option value="82">Herstellung von Frucht- und Gemüsesäften</option>
                                        <option value="83">Sonstige Verarbeitung von Obst und Gemüse</option>
                                        <option value="84">Herstellung von Ölen und Fetten (ohne Margarine u.ä. Nahrungsfette)</option>
                                        <option value="85">Herstellung von Margarine u.ä. Nahrungsfetten</option>
                                        <option value="86">Milchverarbeitung (ohne Herstellung von Speiseeis)</option>
                                        <option value="87">Herstellung von Speiseeis</option>
                                        <option value="88">Mahl- und Schälmühlen</option>
                                        <option value="89">Herstellung von Stärke und Stärkeerzeugnissen</option>
                                        <option value="90">Herstellung von Backwaren (ohne Dauerbackwaren)</option>
                                        <option value="91">Herstellung von Dauerbackwaren</option>
                                        <option value="92">Herstellung von Teigwaren</option>
                                        <option value="93">Herstellung von Zucker</option>
                                        <option value="94">Herstellung von Süßwaren (ohne Dauerbackwaren)</option>
                                        <option value="95">Verarbeitung von Kaffee und Tee, Herstellung von Kaffee-Ersatz</option>
                                        <option value="96">Herstellung von Würzmitteln und Soßen</option>
                                        <option value="97">Herstellung von Fertiggerichten</option>
                                        <option value="98">Herstellung von homogenisierten und diätetischen Nahrungsmitteln</option>
                                        <option value="99">Herstellung von sonstigen Nahrungsmitteln a.n.g.</option>
                                        <option value="100">Herstellung von Futtermitteln für Nutztiere</option>
                                        <option value="101">Herstellung von Futtermitteln für sonstige Tiere</option>
                                        <option value="102">Getränkeherstellung</option>
                                        <option value="103">Herstellung von Spirituosen</option>
                                        <option value="104">Herstellung von Traubenwein</option>
                                        <option value="105">Herstellung von Apfelwein und anderen Fruchtweinen</option>
                                        <option value="106">Herstellung von Wermutwein und sonstigen aromatisierten Weinen</option>
                                        <option value="107">Herstellung von Bier</option>
                                        <option value="108">Herstellung von Malz</option>
                                        <option value="109">Herstellung von Erfrischungsgetränken; Gewinnung natürlicher Mineralwässer</option>
                                        <option value="110">Tabakverarbeitung</option>
                                        <option value="111">Herstellung von Textilien</option>
                                        <option value="112">Spinnstoffaufbereitung und Spinnerei</option>
                                        <option value="113">Weberei</option>
                                        <option value="114">Veredlung von Textilien und Bekleidung</option>
                                        <option value="115">Herstellung von sonstigen Textilwaren</option>
                                        <option value="116">Herstellung von gewirktem und gestricktem Stoff</option>
                                        <option value="117">Herstellung von konfektionierten Textilwaren (ohne Bekleidung)</option>
                                        <option value="118">Herstellung von Teppichen</option>
                                        <option value="119">Herstellung von Seilerwaren</option>
                                        <option value="120">Herstellung von Vliesstoff und Erzeugnissen daraus (ohne Bekleidung)</option>
                                        <option value="121">Herstellung von technischen Textilien</option>
                                        <option value="122">Herstellung von sonstigen Textilwaren a.n.g.</option>
                                        <option value="123">Herstellung von Bekleidung</option>
                                        <option value="124">Herstellung von Bekleidung (ohne Pelzbekleidung)</option>
                                        <option value="125">Herstellung von Pelzwaren</option>
                                        <option value="126">Herstellung von Bekleidung aus gewirktem und gestricktem Stoff</option>
                                        <option value="127">Herstellung von Lederbekleidung</option>
                                        <option value="128">Herstellung von Arbeits- und Berufsbekleidung</option>
                                        <option value="129">Herstellung von sonstiger Oberbekleidung</option>
                                        <option value="130">Herstellung von Wäsche</option>
                                        <option value="131">Herstellung von sonstiger Bekleidung und Bekleidungszubehör a.n.g.</option>
                                        <option value="132">Herstellung von Strumpfwaren</option>
                                        <option value="133">Herstellung von sonstiger Bekleidung aus gewirktem und gestricktem Stoff</option>
                                        <option value="134">Herstellung von Leder, Lederwaren und Schuhen</option>
                                        <option value="135">Herstellung von Leder und Lederwaren (ohne Herstellung von Lederbekleidung)</option>
                                        <option value="136">Herstellung von Schuhen</option>
                                        <option value="137">Herstellung von Leder und Lederfaserstoff; Zurichtung und Färben von Fellen</option>
                                        <option value="138">Lederverarbeitung (ohne Herstellung von Lederbekleidung)</option>
                                        <option value="139">Herstellung von Holz-, Flecht-, Korb- und Korkwaren (ohne Möbel)</option>
                                        <option value="140">Herstellung von Papier, Pappe und Waren daraus</option>
                                        <option value="141">Herstellung von Druckerzeugnissen; Vervielfältigung von bespielten Ton-, Bild- und Datenträgern</option>
                                        <option value="142">Kokerei und Mineralölverarbeitung</option>
                                        <option value="143">Herstellung von chemischen Erzeugnissen</option>
                                        <option value="144">Herstellung von pharmazeutischen Erzeugnissen</option>
                                        <option value="145">Herstellung von Gummi- und Kunststoffwaren</option>
                                        <option value="146">Herstellung von Glas und Glaswaren, Keramik, Verarbeitung von Steinen und Erden</option>
                                        <option value="147">Metallerzeugung und -bearbeitung</option>
                                        <option value="148">Erzeugung von Roheisen, Stahl und Ferrolegierungen</option>
                                        <option value="149">Herstellung von Stahlrohren, Rohrform-, Rohrverschluss- und Rohrverbindungsstücken aus Stahl</option>
                                        <option value="150">Sonstige erste Bearbeitung von Eisen und Stahl</option>
                                        <option value="151">Erzeugung und erste Bearbeitung von NE-Metallen</option>
                                        <option value="152">Gießereien</option>
                                        <option value="153">Herstellung von Blankstahl</option>
                                        <option value="154">Herstellung von Kaltband mit einer Breite von weniger als 600 mm</option>
                                        <option value="155">Herstellung von Kaltprofilen</option>
                                        <option value="156">Herstellung von kaltgezogenem Draht</option>
                                        <option value="157">Erzeugung und erste Bearbeitung von Edelmetallen</option>
                                        <option value="158">Erzeugung und erste Bearbeitung von Aluminium</option>
                                        <option value="159">Erzeugung und erste Bearbeitung von Blei, Zink und Zinn</option>
                                        <option value="160">Erzeugung und erste Bearbeitung von Kupfer</option>
                                        <option value="161">Erzeugung und erste Bearbeitung von sonstigen NE-Metallen</option>
                                        <option value="162">Aufbereitung von Kernbrennstoffen</option>
                                        <option value="163">Eisengießereien</option>
                                        <option value="164">Stahlgießereien</option>
                                        <option value="165">Leichtmetallgießereien</option>
                                        <option value="166">Buntmetallgießereien</option>
                                        <option value="167">Herstellung von Metallerzeugnissen</option>
                                        <option value="168">Stahl- und Leichtmetallbau</option>
                                        <option value="169">Herstellung von Metalltanks und -behältern; Herstellung von Heizkörpern und -kesseln für Zentralheizungen</option>
                                        <option value="170">Herstellung von Dampfkesseln (ohne Zentralheizungskessel)</option>
                                        <option value="171">Herstellung von Waffen und Munition</option>
                                        <option value="172">Herstellung von Waffen und Munition</option>
                                        <option value="173">Herstellung von Schmiede-, Press-, Zieh- und Stanzteilen, gewalzten Ringen und pulvermetallurgischen Erzeugnissen</option>
                                        <option value="174">Oberflächenveredlung und Wärmebehandlung; Mechanik a.n.g.</option>
                                        <option value="175">Herstellung von Schneidwaren, Werkzeugen, Schlössern und Beschlägen aus unedlen Metallen</option>
                                        <option value="176">Herstellung von sonstigen Metallwaren</option>
                                        <option value="177">Herstellung von Metallkonstruktionen</option>
                                        <option value="178">Herstellung von Ausbauelementen aus Metall</option>
                                        <option value="179">Herstellung von Heizkörpern und –kesseln für Zentralheizungen</option>
                                        <option value="180">Herstellung von Sammelbehältern, Tanks u.ä. Behältern aus Metall</option>
                                        <option value="181">Oberflächenveredlung und Wärmebehandlung</option>
                                        <option value="182">Mechanik a.n.g.</option>
                                        <option value="183">Herstellung von Schneidwaren und Bestecken aus unedlen Metallen</option>
                                        <option value="184">Herstellung von Schlössern und Beschlägen aus unedlen Metallen</option>
                                        <option value="185">Herstellung von Werkzeugen</option>
                                        <option value="186">Herstellung von Fässern, Trommeln, Dosen, Eimern u.ä. Behältern aus Metall</option>
                                        <option value="187">Herstellung von Verpackungen und Verschlüssen aus Eisen, Stahl und NE-Metall</option>
                                        <option value="188">Herstellung von Drahtwaren, Ketten und Federn</option>
                                        <option value="189">Herstellung von Schrauben und Nieten</option>
                                        <option value="190">Herstellung von sonstigen Metallwaren a.n.g.</option>
                                        <option value="191">Herstellung von Datenverarbeitungsgeräten, elektronischen und optischen Erzeugnissen</option>
                                        <option value="192">Herstellung von elektrischen Ausrüstungen</option>
                                        <option value="193">Maschinenbau</option>
                                        <option value="194">Herstellung von Kraftwagen und Kraftwagenteilen</option>
                                        <option value="195">Sonstiger Fahrzeugbau</option>
                                        <option value="196">Herstellung von Möbeln</option>
                                        <option value="197">Herstellung von sonstigen Waren</option>
                                        <option value="198">Reparatur und Installation von Maschinen und Ausrüstungen</option>
                                        <option value="199">Elektrizitätsversorgung</option>
                                        <option value="200">Wasserversorgung</option>
                                        <option value="201">Abwasserentsorgung</option>
                                        <option value="202">Sammlung, Behandlung und Beseitigung von Abfällen; Rückgewinnung</option>
                                        <option value="203">Beseitigung von Umweltverschmutzungen und sonstige Entsorgung</option>
                                        <option value="204">Hochbau</option>
                                        <option value="205">Tiefbau</option>
                                        <option value="206">Vorbereitende Baustellenarbeiten, Bauinstallation und sonstiges Ausbaugewerbe</option>
                                        <option value="207">Handel mit Kraftfahrzeugen; Instandhaltung und Reparatur von Kraftfahrzeugen</option>
                                        <option value="208">Handelsvermittlung</option>
                                        <option value="209">Großhandel mit landwirtschaftlichen Grundstoffen und lebenden Tieren</option>
                                        <option value="210">Großhandel mit Nahrungs- und Genussmitteln, Getränken und Tabakwaren</option>
                                        <option value="211">Großhandel mit Gebrauchs- und Verbrauchsgütern</option>
                                        <option value="212">Großhandel mit Geräten der Informations- und Kommunikationstechnik</option>
                                        <option value="213">Großhandel mit sonstigen Maschinen, Ausrüstungen und Zubehör</option>
                                        <option value="214">Sonstiger Großhandel</option>
                                        <option value="215">Großhandel ohne ausgeprägten Schwerpunkt</option>
                                        <option value="216">Einzelhandel (ohne Handel mit Kraftfahrzeugen)</option>
                                        <option value="217">Landverkehr und Transport in Rohrfernleitungen</option>
                                        <option value="218">Schifffahrt</option>
                                        <option value="219">Luftfahrt</option>
                                        <option value="220">Lagerei sowie Erbringung von sonstigen Dienstleistungen für den Verkehr</option>
                                        <option value="221">Post-, Kurier- und Expressdienste</option>
                                        <option value="222">Beherbergung</option>
                                        <option value="223">Gastronomie</option>
                                        <option value="224">Verlagswesen</option>
                                        <option value="225">Herstellung, Verleih und Vertrieb von Filmen und Fernsehprogrammen; Kinos; Tonstudios und Verlegen von Musik</option>
                                        <option value="226">Rundfunkveranstalter</option>
                                        <option value="227">Telekommunikation</option>
                                        <option value="228">Erbringung von Dienstleistungen der Informationstechnologie</option>
                                        <option value="229">Informationsdienstleistungen</option>
                                        <option value="230">Erbringung von Finanzdienstleistungen</option>
                                        <option value="231">Versicherungen, Rückversicherungen und Pensionskassen (ohne Sozialversicherung)</option>
                                        <option value="232">Mit den Finanz- und Versicherungsdienstleistungen verbundene Tätigkeiten</option>
                                        <option value="233">Kauf und Verkauf von eigenen Grundstücken, Gebäuden und Wohnungen</option>
                                        <option value="234">Rechts- und Steuerberatung, Wirtschaftsprüfung</option>
                                        <option value="235">Verwaltung und Führung von Unternehmen und Betrieben; Unternehmensberatung</option>
                                        <option value="236">Architektur- und Ingenieurbüros; technische, physikalische und chemische Untersuchung</option>
                                        <option value="237">Forschung und Entwicklung</option>
                                        <option value="238">Werbung und Marktforschung</option>
                                        <option value="239">Sonstige freiberufliche, wissenschaftliche und technische Tätigkeiten</option>
                                        <option value="240">Veterinärwesen</option>
                                        <option value="241">Vermietung von beweglichen Sachen</option>
                                        <option value="242">Vermietung von Kraftwagen</option>
                                        <option value="243">Vermittlung und Überlassung von Arbeitskräften</option>
                                        <option value="244">Reisebüros, Reiseveranstalter und Erbringung sonstiger Reservierungsdienstleistungen</option>
                                        <option value="245">Wach- und Sicherheitsdienste sowie Detekteien</option>
                                        <option value="246">Gebäudebetreuung; Garten- und Landschaftsbau</option>
                                        <option value="247">Erbringung von wirtschaftlichen Dienstleistungen für Unternehmen und Privatpersonen a.n.g.</option>
                                        <option value="248">Öffentliche Verwaltung</option>
                                        <option value="249">Verteidigung</option>
                                        <option value="250">Kindergärten und Vorschulen</option>
                                        <option value="251">Gesundheitswesen</option>
                                        <option value="252">Heime (ohne Erholungs- und Ferienheime)</option>
                                        <option value="253">Sozialwesen (ohne Heime)</option>
                                        <option value="254">Kreative, künstlerische und unterhaltende Tätigkeiten</option>
                                        <option value="255">Bibliotheken, Archive, Museen, botanische und zoologische Gärten</option>
                                        <option value="256">Spiel-, Wett- und Lotteriewesen</option>
                                        <option value="257">Erbringung von Dienstleistungen des Sports, der Unterhaltung und der Erholung</option>
                                        <option value="258">Interessenvertretungen sowie kirchliche und sonstige religiöse Vereinigungen (ohne Sozialwesen und Sport)</option>
                                        <option value="259">Reparatur von Datenverarbeitungsgeräten und Gebrauchsgütern</option>
                                        <option value="260">Private Haushalte mit Hauspersonal</option>
                                        <option value="261">Herstellung von Waren und Erbringung von Dienstleistungen durch private Haushalte für den Eigenbedarf ohne ausgeprägten Schwerpunkt</option>
                                        <option value="262">Exterritoriale Organisationen und Körperschaften</option>
                                        
                                    </b-form-select>
                                </b-form-group>

                            </b-col>
                            <b-col>
                                <b-form-group :label="$t(`user.company`)" label-for="user-company">
                                    <b-form-input id="user-company" type="text" v-model="user.company" :state="!$v.user.company.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.logo`)" label-for="user-logo">
                                    <template v-if="user.logo">
                                        <b-img :src="user.logo" class="preview-image"></b-img>
                                    </template>
                                    <p class="text-primary" v-if="!user.logo"><strong>{{ $t("user.not_available") }}</strong></p>
                                </b-form-group>

                                <b-form-group :label="$t(`user.logoupload`)" label-for="user-logo">
                                    <b-form-file id="user-logo" v-model="file" placeholder="Logo auswählen..." style="background-color: #fdfdfd"></b-form-file>
                                </b-form-group>

                                <b-form-group :label="$t(`user.address`)" label-for="user-address">
                                    <b-form-textarea id="user-address" type="text" v-model="user.address" :state="!$v.user.address.$error ? null : false" :no-resize="true" :rows="3" :max-rows="3" style="background-color: #fdfdfd"></b-form-textarea>
                                </b-form-group>

                                <b-form-group :label="$t(`user.employees`)" label-for="user-employees">
                                    <b-form-input id="user-employees" type="text" v-model="user.employee_count" :state="!$v.user.employee_count.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.sales`)" label-for="user-sales">
                                    <b-form-input id="user-sales" type="text" v-model="user.sales" :state="!$v.user.sales.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-button type="submit" variant="primary"><b-icon icon="save" />{{ $t("user.btn_save") }}</b-button>
                    </b-form>
                </b-card>
            </b-tab>
            <b-tab :title="$t(`user.tab_selfdisclosure`)">
                <b-container>
                    <template v-for="questionnaire in questionnaires">
                        <b-row v-bind:key="questionnaire.id">
                            <b-col>
                                <QuestionnaireComponent :questionnaire="questionnaire" :answers="answers" />
                            </b-col>
                        </b-row>
                    </template>
                </b-container>
            </b-tab>
        </b-tabs>
    </div>
    <b-modal id="termsOfService" ref="termsOfService" :title="$t('profile.termsofservicetitle')" size="lg" :cancelDisabled="true">
        <template slot="modal-footer">
            <b-button @click="$refs.termsOfService.hide()"><b-icon icon="check2" />{{ $t("user.modal_btn_ok") }}</b-button>
        </template>
        <TermsOfServiceComponent />
    </b-modal>
</div>
</template>
<script>
import { mapGetters } from 'vuex';
import userService from '../../../../services/UserService';
import { helpers, required, email, sameAs, numeric } from 'vuelidate/lib/validators';
import TermsOfServiceComponent from '../../content/TermsOfService';
import QuestionnaireService from '../../../../services/QuestionnaireService';
import DownloadService from '../../../../services/DownloadService';
import QuestionnaireComponent from './Questionnaire';

export default {
    name: 'profile',
    components: {
        TermsOfServiceComponent,
        QuestionnaireComponent
    },
    data() {
        return {
            doneLoading: false,
            hideAgreements: false,
            questionnaires: [],
            answers: {},
            user: {},
            file: {},
            selectedFragen: [],
            totalSum: 0
        }
    },
    created() {
        this.loadUserProfile();
    },
    validations: {
        user: {
            name: {
                required
            },
            password_repeat: {
                sameAsPassword: sameAs('password')
            },
            contact: {
                required
            },
            phone: {
                required
            },
            company: {
                required
            },
            address: {
                required
            },
            employee_count: {
                required, numeric
            },
            sales: {
                required
            },
            email: {
                required,
                email
            }
        }
    },
    methods: {
        loadUserProfile() {
            this.$store.commit('loading/setLoading', true);

            userService.fetchProfile()
                .then(userResponse => {
                  this.user = userResponse.data;

                  if(this.user.accepted_dsgvo && this.user.accepted_terms_of_service) {
                    this.hideAgreements = true;
                  }

                  return QuestionnaireService.fetchForMe()
                      .then(response => {
                        this.questionnaires = response.data;

                        return QuestionnaireService.fetchMyAnswers()
                            .then(answers => {
                              this.$store.commit('loading/setLoading', false);
                              this.answers = answers.data;
                              this.doneLoading = true;
                            });
                      });
                })
        },
        onSubmitUserInfo() {
            this.$v.$touch();

            if(this.$v.$error) {
                return;
            }
            userService.updateProfile(this.user, this.file)
                .then(response => {
                    if(response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: this.$t("user.notify_profile_save"),
                            type: 'success',
                            text: this.$t("user.notify_profile_message")
                        });
                        // TODO: Does not work yet
                        this.loadUserProfile();
                    } else {
                        this.$notify({
                            group: 'global',
                            title: this.$t("user.notify_profile_error"),
                            type: 'error',
                            text: this.$t("user.notify_profile_errormessage")
                        });
                    }
                });
        },
        showTermsOfService() {
            this.$refs.termsOfService.show();
        },
    },
    computed: {
        ...mapGetters({
        hasRole: 'auth/hasRole'
        }),
    }
};
</script>